<template>
<div>
  <slot></slot>
  <table class="d-flex justify-center tbl-entry tbl-privacy mt-3">
    <tr class="d-flex">
      <th class="pr-3 privacy-label">
        各種規約と利用料金について
        <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
      </th>
      <td class="privacy-chk">
        <label for="rule-chk">
          <input type="checkbox" id="rule-chk" class="checkbox-input" @click="ruleCheckClick" required>
          <span class="checkbox-parts" v-html="$t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TEXT')"></span>
        </label>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  props : {

  },
  mounted() {
    this.resetRuleCheck()
  },
  data() {
    return {
      ruleCheckBtnClicked : false
    }
  },
  methods : {
    resetRuleCheck() {
      // 更新ボタンの活性・非活性
      const rule = document.getElementById('rule-chk')
      if (rule) {
        rule.checked = false
        this.ruleCheckBtnClicked = false
      }
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
      this.$emit('change', this.ruleCheckBtnClicked)
    },
  },
  computed : {
  }
})
</script>
<style scoped lang="scss">
  em.req {
    display: inline-block;
    background-color: #E80000;
    width: 35px;
    height: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    line-height: 19px;
  }
  em.req {
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
  .privacy-label {
    width: max-content;
    color: #000;
  }

  @media only screen and (max-width: 767px) {
    .privacy-chk {
      width: 100%;
      display: flex;
      width: 100%;
      align-items: center;
    }
    .privacy-label {
      width: 100%;
    }
  }
  </style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
